<template>
  <div class="standard-management-container">
    <el-row>
      <el-button
        icon="el-icon-circle-plus-outline"
        type="primary"
        @click="handleAddTeachStandard"
        >添加教学标准</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-form inline :model="teachStandardCondition">
        <el-form-item
          prop="experimentTeachCode"
          label="编号"
          class="formItemBoxStyle"
        >
          <el-input
            v-model="teachStandardCondition.experimentTeachCode"
            placeholder="请输入"
            @blur="fetchTeachStandardInfoData"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="experimentTeachName"
          label="标准名称"
          class="formItemBoxStyle"
        >
          <el-input
            v-model="teachStandardCondition.experimentTeachName"
            placeholder="请输入"
            @blur="fetchTeachStandardInfoData"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属学段" class="formItemBoxStyle">
          <el-select
            v-model="teachStandardCondition.periodId"
            @change="handleTeachPeriodSelect"
          >
            <el-option label="全部学段" value=""></el-option>
            <el-option
              v-for="item in teachPeriodData"
              :key="item.sysDicId"
              :label="item.sysDicName"
              :value="item.sysDicId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属年级" class="formItemBoxStyle">
          <el-select
            v-model="teachStandardCondition.gradeId"
            @change="fetchTeachStandardInfoData"
          >
            <el-option label="全部年级" value=""></el-option>
            <el-option
              v-for="item in teachGradeData"
              :key="item.sysSchoolClassGradeId"
              :label="item.sysSchoolClassGradeName"
              :value="item.sysSchoolClassGradeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" class="formItemBoxStyle">
          <el-select
            v-model="teachStandardCondition.enabled"
            @change="fetchTeachStandardInfoData"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="启用" :value="1"></el-option>
            <el-option label="停用" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleResetTeachStandardBtnClick">重置</el-button>
          <el-button type="primary" @click="handleQueryTeachStandardBtnClick"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        :data="teachStandardDataList"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="experimentTeachCode"
          label="编号"
          center
        ></el-table-column>
        <el-table-column
          prop="experimentTeachName"
          label="教学标准名称"
          center
        ></el-table-column>
        <el-table-column
          prop="periodName"
          label="所属学段"
          center
        ></el-table-column>
        <el-table-column
          prop="gradeName"
          label="所属年级"
          center
        ></el-table-column>
        <el-table-column prop="experimentTeachCount" label="实验标准数" center>
          <template slot-scope="scoped">
            <div
              style="color: #00f; text-decoration: underline; cursor: pointer"
              @click="
                handleOpenExperimentTeachCountDialogBtnClick(
                  scoped.row.experimentTeachId
                )
              "
            >
              {{ scoped.row.experimentTeachCount }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createName"
          label="创建者"
          center
        ></el-table-column>
        <el-table-column prop="createTime" label="创建时间" center sortable>
          <template slot-scope="scoped">
            <span>{{ handleTimeFormate(scoped.row.createTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="enabled" label="状态" center>
          <template slot-scope="scoped">
            {{ scoped.row.enabled === 1 ? "启用" : "停用" }}
          </template>
        </el-table-column>
        <el-table-column label="操作" center>
          <template slot-scope="scoped">
            <!-- 停用状态 -->
            <div v-if="scoped.row.enabled === 2">
              <span
                style="cursor: pointer; color: green"
                @click="handleEditTeachStandardBtnClick(scoped.row)"
              >
                编辑
              </span>
              <span
                style="
                  cursor: pointer;
                  color: rgb(64, 158, 255);
                  margin: 0 10px;
                "
                @click="
                  handleOpenChangeTeachStandardDialog(
                    '启用',
                    scoped.row.experimentTeachId
                  )
                "
              >
                启用
              </span>
              <span
                @click="
                  handleOpenChangeTeachStandardDialog(
                    '删除',
                    scoped.row.experimentTeachId
                  )
                "
                style="cursor: pointer; color: red"
              >
                删除
              </span>
            </div>
            <!-- 启动状态 -->
            <div v-else>
              <span
                style="cursor: pointer; color: green; margin-right: 5px"
                @click="handleEditTeachStandardBtnClick(scoped.row)"
              >
                编辑
              </span>
              <span
                style="cursor: pointer; color: red; margin-left: 5px"
                @click="
                  handleOpenChangeTeachStandardDialog(
                    '停用',
                    scoped.row.experimentTeachId
                  )
                "
              >
                停用
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeTableDataMsgPage"
        @current-change="handleCurrentChangeTableDataMsgPage"
        :current-page="teachStandardDataMsgPage.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="teachStandardDataMsgPage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="teachStandardDataMsgPage.pageTotal"
      ></el-pagination>
    </el-row>
    <!-- 新增或编辑标准弹框 -->
    <el-dialog
      :visible.sync="addOrEditStandardDialogVisible"
      :title="addOrEditTeachStandard === 1 ? '添加标准' : '编辑标准'"
      top="3%"
    >
      <div style="width: 600px; margin: 0 auto">
        <el-form
          :model="addOrEditTeachStandardForm"
          ref="addOrEditTeachStandardForm"
          class="addOrEditTeachStandardForm"
          :rules="rules"
        >
          <el-form-item class="formItem" prop="experimentTeachName">
            <span class="redStar">教学标准名称：</span>
            <el-input
              v-model.trim="addOrEditTeachStandardForm.experimentTeachName"
              placeholder="请输入"
              maxlength="12"
              style="width: 450px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item class="formItem" prop="experimentTeachCode">
            <span class="redStar" style="margin-left: 55px">编号：</span>
            <el-input
              v-model.trim="addOrEditTeachStandardForm.experimentTeachCode"
              placeholder="请输入"
              maxlength="12"
              style="width: 450px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item class="formItem">
            <span class="redStar" style="margin-left: 28px">所属学段：</span>
            <el-select
              v-model="addOrEditTeachStandardForm.periodId"
              placeholder="请选择"
              style="width: 450px"
              @change="handleAddLabFormPeriodChange"
            >
              <el-option
                v-for="item in teachPeriodData"
                :key="item.sysDicId"
                :label="item.sysDicName"
                :value="item.sysDicId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="formItem">
            <span class="redStar" style="margin-left: 28px">所属年级：</span>
            <el-select
              v-model="addOrEditTeachStandardForm.gradeId"
              placeholder="请选择"
              style="width: 450px"
            >
              <el-option
                v-for="item in addLabFormGradeData"
                :key="item.sysSchoolClassGradeId"
                :label="item.sysSchoolClassGradeName"
                :value="item.sysSchoolClassGradeId"
              ></el-option>
            </el-select>
          </el-form-item>
          <div
            style="
              width: 150%;
              height: 1px;
              background-color: #ccc;
              margin-left: -150px;
            "
          ></div>
          <el-form-item class="formItem">
            <div
              style="
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
              "
            >
              <div class="redStar">实验教学清单：</div>
              <div
                style="color: #00f; text-decoration: underline; cursor: pointer"
                @click="handleOpenAddLabDialog"
              >
                + 添加实验
              </div>
            </div>
            <div class="add-lab-table-container">
              <el-table
                :header-cell-style="{
                  'text-align': 'center',
                  backgroundColor: '#dfe6ec',
                }"
                :cell-style="{ 'text-align': 'center' }"
                :data="addLabTableDataList"
                border
              >
                <el-table-column
                  prop="subjectName"
                  label="所属科目"
                  width="80px"
                >
                </el-table-column>
                <el-table-column
                  prop="experimentName"
                  label="实验名称"
                  width="160px"
                >
                </el-table-column>
                <el-table-column
                  prop="experimentCode"
                  label="实验编号"
                  width="100px"
                >
                </el-table-column>
                <el-table-column prop="experimentPurpose" label="实验目的">
                </el-table-column>
                <el-table-column width="60px">
                  <template slot-scope="scoped">
                    <div
                      style="font-size: 24px; cursor: pointer; color: red"
                      @click="handleDeleteAddedLabInfoBtnClick(scoped.row)"
                    >
                      <i class="el-icon-delete"></i>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
          <el-form-item>
            <div style="text-align: right">
              <el-button
                @click="
                  () => {
                    addOrEditStandardDialogVisible = false;
                    addOrEditTeachStandardForm = {
                      experimentTeachName: '',
                      experimentTeachCode: '',
                      periodId: '',
                      gradeId: '',
                    };
                  }
                "
                >取消</el-button
              >
              <el-button
                type="primary"
                @click="handleConfirmAddOrEditTeachStandardBtnClick"
                >确认添加</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 添加实验弹框 -->
    <el-dialog title="添加实验" :visible.sync="addLabDialogVisible">
      <div style="margin: 0 10px">
        <el-form inline :model="addLabInfoDataForm">
          <el-form-item label="所属科目" prop="subjectId">
            <el-select
              v-model="addLabInfoDataForm.subjectId"
              style="width: 120px"
              @change="handleQueryLabInfoBtnClick"
            >
              <el-option label="全部科目" value=""></el-option>
              <el-option
                v-for="item in labInfoSubjectArr"
                :key="item.subjectId"
                :label="item.subjectName"
                :value="item.subjectId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="实验名称" prop="experimentName">
            <el-input
              v-model="addLabInfoDataForm.experimentName"
              placeholder="请输入"
              style="width: 180px"
              @input="handleQueryLabInfoBtnClick"
            ></el-input>
          </el-form-item>
          <el-form-item label="实验编号" prop="experimentCode">
            <el-input
              v-model="addLabInfoDataForm.experimentCode"
              placeholder="请输入"
              style="width: 180px"
              @input="handleQueryLabInfoBtnClick"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleResetLabInfoBtnClick" v-throttle
              >重置</el-button
            >
            <el-button
              type="primary"
              @click="handleQueryLabInfoBtnClick"
              v-throttle
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <el-table
          :data="labInfoTableData"
          :row-key="rowKey"
          ref="labInfoTableData"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          @select="handleRowSelect"
          @select-all="handleAllRowSelect"
        >
          <el-table-column
            label="所属科目"
            prop="subjectName"
            width="100"
          ></el-table-column>
          <el-table-column
            label="实验名称"
            prop="experimentName"
            width="240"
          ></el-table-column>
          <el-table-column
            label="实验编号"
            prop="experimentCode"
            width="120"
          ></el-table-column>
          <el-table-column
            label="实验目的"
            prop="experimentPurpose"
          ></el-table-column>
          <el-table-column type="selection" width="55"> </el-table-column>
        </el-table>
        <!-- 分页组件 -->
        <el-row style="margin-top: 20px">
          <el-pagination
            @size-change="handleSizeChangeLabInfoTableDataMsgPage"
            @current-change="handleCurrentChangeLabInfoTableDataMsgPage"
            :current-page="labInfoTableDataMsgPage.pageIndex"
            :page-sizes="[10, 20, 50, 100, 150, 200]"
            :page-size="labInfoTableDataMsgPage.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="labInfoTableDataMsgPage.pageTotal"
          ></el-pagination>
        </el-row>
        <div style="margin-top: 10px; text-align: right">
          <el-button
            @click="
              () => {
                addLabDialogVisible = false;
              }
            "
            >取消</el-button
          >
          <el-button type="primary" @click="handleConfirmAddLabInfoBtnClick"
            >确认添加</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 查看实验数量弹框 -->
    <el-dialog title="实验清单" :visible.sync="experimentNumberDialogVisible">
      <el-form inline :model="experimentNumberDialogCondition">
        <el-form-item label="所属科目" prop="subjectName">
          <el-select
            v-model="experimentNumberDialogCondition.subjectName"
            style="width: 150px"
            @change="handleQueryExperimentNumber"
          >
            <el-option label="全部科目" value=""></el-option>
            <el-option label="物理" value="物理"></el-option>
            <el-option label="生物" value="生物"></el-option>
            <el-option label="化学" value="化学"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="实验名称" prop="experimentName">
          <el-input
            v-model="experimentNumberDialogCondition.experimentName"
            placeholder="请输入"
            style="width: 150px"
            @input="handleQueryExperimentNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="实验编号" prop="experimentCode">
          <el-input
            v-model="experimentNumberDialogCondition.experimentCode"
            placeholder="请输入"
            style="width: 150px"
            @input="handleQueryExperimentNumber"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleResetExperimentNumber">重置</el-button>
          <el-button type="primary" @click="handleQueryExperimentNumber"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="experimentNumberDataList"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        height="500px"
      >
        <el-table-column label="所属科目" prop="subjectName"></el-table-column>
        <el-table-column
          label="实验名称"
          prop="experimentName"
        ></el-table-column>
        <el-table-column
          label="实验编号"
          prop="experimentCode"
        ></el-table-column>
        <el-table-column
          label="实验目的"
          prop="experimentPurpose"
          width="400px"
        ></el-table-column>
      </el-table>

      <div style="margin-top: 20px; text-align: right">
        <el-button
          @click="
            () => {
              experimentNumberDialogVisible = false;
            }
          "
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTeachStandardInfoApi,
  addTeachStandardApi,
  deleteTeachStandardApi,
  getTeachStandardLabInfoApi,
  getTeachStandardDetailApi,
  updateTeachStandardApi,
} from "@/api/standard/standardManagement";
import {
  getDicFormInfoApi,
  getGradeDicInfoApi,
} from "@/api/standard/dictionaryForm";
import {
  getLabInfoFormMsgApi,
  getMaterialMenuInfoApi,
} from "@/api/standard/menuManagement";
import { formatDate } from "@/utils/util.js";
export default {
  name: "standardManagement",
  data() {
    const checkExperimentTeachCode = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z]/g;
      if (reg.test(this.addOrEditTeachStandardForm.experimentTeachCode)) {
        callback(new Error("编号只能是数字或字母"));
      } else {
        callback();
      }
    };
    return {
      teachStandardCondition: {
        experimentTeachCode: "",
        experimentTeachName: "",
        periodId: "",
        gradeId: "",
        enabled: "",
      },
      teachPeriodData: [],
      teachGradeData: [],
      addLabFormGradeData: [],
      teachStandardDataList: [],
      teachStandardDataMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      addOrEditTeachStandard: 1, // 1表示新增，0表示编辑
      addOrEditStandardDialogVisible: false,
      addOrEditTeachStandardForm: {
        experimentTeachName: "",
        experimentTeachCode: "",
        periodId: "",
        gradeId: "",
      },
      rules: {
        experimentTeachName: [
          { required: true, message: "请输入教学标准名称", trigger: "blur" },
        ],
        experimentTeachCode: [
          { required: true, message: "请输入编号", trigger: "blur" },
          { validator: checkExperimentTeachCode, trigger: "blur" },
        ],
        periodId: [
          { required: true, message: "请选择学段", trigger: "change" },
        ],
        gradeId: [{ required: true, message: "请选择年级", trigger: "change" }],
      },
      addLabTableDataList: [],
      addLabDialogVisible: false,
      dicFormCode: 5,
      addLabInfoDataForm: {
        subjectId: "",
        experimentName: "",
        experimentCode: "",
      },
      labInfoSubjectArr: [],
      labInfoTableData: [],
      labInfoTableDataMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      prepareAddLabInfoList: [],
      rowKey: "experimentId",
      experimentTeachId: "",
      experimentDTOListBeforeEdit: [],
      experimentNumberDialogVisible: false,
      experimentNumberDialogCondition: {
        subjectName: "",
        experimentName: "",
        experimentCode: "",
      },
      experimentNumberDataList: [],
      originExperimentNumberDataList: [],
    };
  },
  created() {
    this.fetchTeachStandardInfoData();
    this.fetchDicFormInfoData();
    this.fetchDicSubjectInfoData();
  },
  methods: {
    // 分页获取展示所有【教学标准信息表信息】
    fetchTeachStandardInfoData() {
      getTeachStandardInfoApi(
        this.teachStandardDataMsgPage.pageIndex,
        this.teachStandardDataMsgPage.pageSize,
        this.teachStandardCondition
      ).then((res) => {
        if (res.success) {
          this.teachStandardDataList = res.data;
          this.teachStandardDataMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取字典表信息
    fetchDicFormInfoData() {
      getDicFormInfoApi(this.dicFormCode).then((res) => {
        if (res.success) {
          this.teachPeriodData = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取实验信息
    fetchLabInfoData(data = this.addLabInfoDataForm) {
      getLabInfoFormMsgApi(
        this.labInfoTableDataMsgPage.pageIndex,
        this.labInfoTableDataMsgPage.pageSize,
        data
      ).then((res) => {
        if (res.success) {
          this.labInfoTableData = res.data;
          this.labInfoTableDataMsgPage.pageTotal = res.total;
          this.handleSelectRowFunc();
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取字典表科目信息
    fetchDicSubjectInfoData() {
      getMaterialMenuInfoApi({}).then((res) => {
        if (res.success) {
          if (res.data.length > 0) {
            this.labInfoSubjectArr = [];
            res.data.forEach((item) => {
              this.labInfoSubjectArr.push({
                subjectName: item.sysDicDTO.sysDicName,
                subjectId: item.sysDicDTO.sysDicId,
              });
            });
          }
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 新增教学标准按钮
    handleAddTeachStandard() {
      this.addOrEditTeachStandard = 1;
      this.addOrEditTeachStandardForm = {
        experimentTeachName: "",
        experimentTeachCode: "",
        periodId: "",
        gradeId: "",
      };
      this.addLabTableDataList = [];
      this.prepareAddLabInfoList = [];
      this.addOrEditStandardDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrEditTeachStandardForm.clearValidate();
      });
    },
    // 编辑教学标准按钮
    handleEditTeachStandardBtnClick(row) {
      getTeachStandardDetailApi(row.experimentTeachId).then(async (res) => {
        // console.log(res,"res")
        if (res.success) {
          const { data } = res;
          // 填充数据
          this.addOrEditTeachStandardForm = {
            experimentTeachName: data.experimentTeachName,
            experimentTeachCode: data.experimentTeachCode,
            periodId: data.periodId,
            gradeId: data.gradeId,
          };
          const res111 = await getGradeDicInfoApi(data.periodId);
          this.addLabFormGradeData = res111.data;
          this.addLabTableDataList = data.experimentDTOList;
          this.experimentDTOListBeforeEdit = [...data.experimentDTOList];
          this.experimentTeachId = data.experimentTeachId;
          this.addOrEditTeachStandard = 0;
          this.addOrEditStandardDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
      // this.addOrEditTeachStandard = 0;
      // this.addOrEditStandardDialogVisible = true;
    },
    // 确认新增或编辑按钮
    handleConfirmAddOrEditTeachStandardBtnClick() {
      this.$refs.addOrEditTeachStandardForm.validate((valid) => {
        if (valid) {
          if (
            this.addOrEditTeachStandardForm.periodId === "" ||
            this.addOrEditTeachStandardForm.gradeId === ""
          ) {
            this.$message.warning("请完成表单填写!");
            return;
          }
          if (this.addLabTableDataList.length === 0) {
            this.$message.warning("请添加实验教学清单!");
            return;
          }
          if (this.addOrEditTeachStandard === 1) {
            // 添加操作
            let addExperimentIds = [];
            this.addLabTableDataList.forEach((item) => {
              addExperimentIds.push(item.experimentId);
            });
            let addTeachStandardParams = {
              addExperimentIds,
              ...this.addOrEditTeachStandardForm,
              enabled: 2,
            };
            addTeachStandardApi(addTeachStandardParams).then((res) => {
              if (res.success) {
                this.$message.success("新增教学标准成功!");
                this.fetchTeachStandardInfoData();
                this.addOrEditStandardDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            // 编辑操作
            let addExperimentIds = [];
            let delExperimentTeachRelationIds = [];
            let experimentIdBefore = this.experimentDTOListBeforeEdit.map(
              (item) => ({
                experimentId: item.experimentId,
                experimentTeachRelationId: item.experimentTeachRelationId,
              })
            );
            let tempExperimentIdBefore = this.experimentDTOListBeforeEdit.map(
              (item) => item.experimentId
            );
            let experimentIdAfter = this.addLabTableDataList.map(
              (item) => item.experimentId
            );
            experimentIdBefore.forEach((item) => {
              if (experimentIdAfter.indexOf(item.experimentId) === -1) {
                delExperimentTeachRelationIds.push(
                  item.experimentTeachRelationId
                );
              }
            });
            experimentIdAfter.forEach((item) => {
              if (tempExperimentIdBefore.indexOf(item) === -1) {
                addExperimentIds.push(item);
              }
            });

            // console.log(addExperimentIds, delExperimentTeachRelationIds);
            let updateTeachStandardParams = {
              addExperimentIds,
              delExperimentTeachRelationIds,
              ...this.addOrEditTeachStandardForm,
              experimentTeachId: this.experimentTeachId,
            };
            updateTeachStandardApi(updateTeachStandardParams).then((res) => {
              // console.log(res);
              if (res.success) {
                this.$message.success("编辑教学标准成功!");
                this.fetchTeachStandardInfoData();
                this.addOrEditStandardDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },
    // 开启确认弹框，进行启用，禁用或删除操作
    handleOpenChangeTeachStandardDialog(val, experimentTeachId) {
      this.$confirm(`确定${val}该教学标准吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (val === "删除") {
            // 进行删除操作
            deleteTeachStandardApi(experimentTeachId).then((res) => {
              if (res.success) {
                this.fetchTeachStandardInfoData();
                this.$message({
                  type: "success",
                  message: `${val}成功!`,
                });
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            let params = {
              experimentTeachId,
              enabled: val === "启用" ? 1 : 2,
            };
            updateTeachStandardApi(params).then((res) => {
              if (res.success) {
                this.fetchTeachStandardInfoData();
                this.$message({
                  type: "success",
                  message: `${val}成功!`,
                });
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${val}`,
          });
        });
    },
    // 打开添加实验弹框
    handleOpenAddLabDialog() {
      this.prepareAddLabInfoList = [...this.addLabTableDataList];
      this.labInfoTableDataMsgPage.pageIndex = 1;
      this.labInfoTableDataMsgPage.pageSize = 10;
      this.fetchLabInfoData();
      this.addLabDialogVisible = true;
    },
    // 删除实验信息按钮（垃圾桶按钮）
    handleDeleteAddedLabInfoBtnClick(row) {
      // this.addLabTableDataList = [];
      // this.prepareAddLabInfoList = [];
      for (let i = 0; i < this.addLabTableDataList.length; i++) {
        if (this.addLabTableDataList[i].experimentId === row.experimentId) {
          this.addLabTableDataList.splice(i, 1);
          break;
        }
      }
      for (let i = 0; i < this.prepareAddLabInfoList.length; i++) {
        if (this.prepareAddLabInfoList[i].experimentId === row.experimentId) {
          this.prepareAddLabInfoList.splice(i, 1);
          break;
        }
      }
    },
    // 学段选择框选择事件
    handleTeachPeriodSelect() {
      // 根据这个 id 发送请求，获取年级数据,teachGradeData
      this.teachStandardCondition.gradeId = "";
      getGradeDicInfoApi(this.teachStandardCondition.periodId).then((res) => {
        if (res.success) {
          this.teachGradeData = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 添加标准弹框中学段选择框选择事件
    handleAddLabFormPeriodChange() {
      this.addOrEditTeachStandardForm.gradeId = "";
      getGradeDicInfoApi(this.addOrEditTeachStandardForm.periodId).then(
        (res) => {
          if (res.success) {
            this.addLabFormGradeData = res.data;
          } else {
            this.$message.error(res.msg);
            return;
          }
        }
      );
    },
    // 打开标准实验数弹框
    handleOpenExperimentTeachCountDialogBtnClick(id) {
      getTeachStandardDetailApi(id).then((res) => {
        if (res.success) {
          console.log(res.data.experimentDTOList);
          this.experimentNumberDataList = res.data.experimentDTOList;
          this.originExperimentNumberDataList = res.data.experimentDTOList;
          this.experimentNumberDialogCondition = {
            subjectName: "",
            experimentName: "",
            experimentCode: "",
          };
          this.experimentNumberDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    handleQueryExperimentNumber() {
      console.log(this.experimentNumberDialogCondition);
      this.experimentNumberDataList = [...this.originExperimentNumberDataList];
      if (this.experimentNumberDialogCondition.subjectName !== "") {
        console.log(1);
        this.experimentNumberDataList = this.experimentNumberDataList.filter(
          (item) =>
            item.subjectName ===
            this.experimentNumberDialogCondition.subjectName
        );
      }
      if (this.experimentNumberDialogCondition.experimentName !== "") {
        console.log(2);
        this.experimentNumberDataList = this.experimentNumberDataList.filter(
          (item) =>
            item.experimentName.indexOf(
              this.experimentNumberDialogCondition.experimentName
            ) > -1
        );
      }
      if (this.experimentNumberDialogCondition.experimentCode !== "") {
        console.log(3);
        this.experimentNumberDataList = this.experimentNumberDataList.filter(
          (item) =>
            item.experimentCode.indexOf(
              this.experimentNumberDialogCondition.experimentCode
            ) > -1
        );
      }
    },
    handleResetExperimentNumber() {
      this.experimentNumberDialogCondition = {
        subjectName: "",
        experimentName: "",
        experimentCode: "",
      };
      this.experimentNumberDataList = [...this.originExperimentNumberDataList];
    },
    // 重置按钮
    handleResetTeachStandardBtnClick() {
      this.teachStandardCondition = {
        experimentTeachCode: "",
        experimentTeachName: "",
        periodId: "",
        enabled: "",
        gradeId: "",
      };
      this.teachStandardDataMsgPage.pageIndex = 1;
      this.teachStandardDataMsgPage.pageSize = 10;
      this.fetchTeachStandardInfoData();
    },
    // 查询按钮
    handleQueryTeachStandardBtnClick() {
      this.teachStandardDataMsgPage.pageIndex = 1;
      this.teachStandardDataMsgPage.pageSize = 10;
      this.fetchTeachStandardInfoData();
    },
    // 查询实验信息按钮
    handleQueryLabInfoBtnClick() {
      this.labInfoTableDataMsgPage.pageIndex = 1;
      this.labInfoTableDataMsgPage.pageSize = 10;
      this.fetchLabInfoData();
    },
    // 重置实验信息按钮
    handleResetLabInfoBtnClick() {
      this.addLabInfoDataForm = {
        subjectId: "",
        experimentName: "",
        experimentCode: "",
      };
      this.fetchLabInfoData();
    },
    // 表格单选数据事件
    handleRowSelect(selection, row) {
      if (selection.indexOf(row) > -1) {
        // console.log("选择")
        this.prepareAddLabInfoList.push(row);
      } else {
        // console.log("取消选择")
        for (let i = 0; i < this.prepareAddLabInfoList.length; i++) {
          if (this.prepareAddLabInfoList[i].experimentId == row.experimentId) {
            this.prepareAddLabInfoList.splice(i, 1);
          }
        }
      }
    },
    // 表格全选事件
    handleAllRowSelect(selection) {
      if (selection.length === 0) {
        // 取消全选
        if (this.labInfoTableData.length > 0) {
          for (let i = 0; i < this.labInfoTableData.length; i++) {
            for (let j = 0; j < this.prepareAddLabInfoList.length; j++) {
              if (
                this.labInfoTableData[i].experimentId ===
                this.prepareAddLabInfoList[j].experimentId
              ) {
                this.prepareAddLabInfoList.splice(j, 1);
                j--;
              }
            }
          }
        }
      } else {
        // 全选
        if (this.labInfoTableData.length > 0) {
          for (let i = 0; i < this.labInfoTableData.length; i++) {
            let temp = true;
            for (let j = 0; j < this.prepareAddLabInfoList.length; j++) {
              if (
                this.labInfoTableData[i].experimentId ===
                this.prepareAddLabInfoList[j].experimentId
              ) {
                temp = false;
                break;
              }
            }
            temp && this.prepareAddLabInfoList.push(this.labInfoTableData[i]);
          }
        }
      }
    },
    // 调用该方法可自动将表格内需要选中的行选中
    handleSelectRowFunc() {
      if (
        this.labInfoTableData.length > 0 &&
        this.prepareAddLabInfoList.length > 0
      ) {
        for (let i = 0; i < this.labInfoTableData.length; i++) {
          for (let j = 0; j < this.prepareAddLabInfoList.length; j++) {
            if (
              this.labInfoTableData[i].experimentId ===
              this.prepareAddLabInfoList[j].experimentId
            ) {
              this.$nextTick(() => {
                this.$refs.labInfoTableData.toggleRowSelection(
                  this.labInfoTableData[i],
                  true
                );
              });
            }
          }
        }
      }
    },
    // 确认添加实验按钮
    handleConfirmAddLabInfoBtnClick() {
      this.addLabTableDataList = [...this.prepareAddLabInfoList];
      this.addLabDialogVisible = false;
    },
    // 改变页容量
    handleSizeChangeTableDataMsgPage(val) {
      this.teachStandardDataMsgPage.pageIndex = 1;
      this.teachStandardDataMsgPage.pageSize = val;
      this.fetchTeachStandardInfoData();
    },
    // 改变当前页
    handleCurrentChangeTableDataMsgPage(val) {
      this.teachStandardDataMsgPage.pageIndex = val;
      this.fetchTeachStandardInfoData();
    },
    // 改变实验信息页容量
    handleSizeChangeLabInfoTableDataMsgPage(val) {
      this.labInfoTableDataMsgPage.pageTotal = val;
      this.labInfoTableDataMsgPage.pageIndex = 1;
      this.fetchLabInfoData();
    },
    // 改变实验信息当前页
    handleCurrentChangeLabInfoTableDataMsgPage(val) {
      this.labInfoTableDataMsgPage.pageIndex = val;
      this.fetchLabInfoData();
    },
    // 日期转换
    handleTimeFormate(timestamp) {
      let timer = new Date(timestamp);
      return formatDate(timer, "yyyy-MM-dd hh-mm-ss");
    },
  },
};
</script>

<style lang="scss" scoped>
.redStar::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

::v-deep .formItem {
  .el-form-item__error {
    margin-left: 110px;
  }
}

.add-lab-table-container {
  width: 130%;
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  margin-left: -100px;
}
</style>
