import { request, noTimeOutReq } from "@/utils/request.js";

// 分页展示所有【教学标准信息表信息】
export function getTeachStandardInfoApi(pageIndex, pageSize, data) {
  return request({
    url: `/sys/experiment/teach/page/${pageIndex}/${pageSize}/list/list`,
    method: "post",
    data,
  });
}

// 新增【教学标准信息数据】
export function addTeachStandardApi(data) {
  return request({
    url: `/sys/experiment/teach/add`,
    method: "post",
    data,
  });
}

// 删除【教学标准信息数据】
export function deleteTeachStandardApi(experimentTeachId) {
  return request({
    url: `/sys/experiment/teach/delete`,
    method: "post",
    params: {
      experimentTeachId,
    },
  });
}

// 获取【教学标准关联的实验】
export function getTeachStandardLabInfoApi(experimentTeachId) {
  return request({
    url: `/sys/experiment/teach/experiment/list`,
    method: "post",
    params: {
      experimentTeachId,
    },
  });
}

/// 获取【教学标准信息数据】详情
export function getTeachStandardDetailApi(experimentTeachId) {
  return request({
    url: `/sys/experiment/teach/get`,
    method: "post",
    params: {
      experimentTeachId,
    },
  });
}

// 修改【教学标准信息数据】
export function updateTeachStandardApi(data) {
  return request({
    url: `/sys/experiment/teach/update`,
    method: "post",
    data,
  });
}
